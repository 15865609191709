import React, { useEffect, useState } from 'react'
import RemoteWork from '../../images/RemoteWork.png'
import DistanceLearning from '../../images/DistanceLearning.png'
import DistantITAssistance from '../../images/DistantITAssistance.png'
import RemoteGame from '../../images/RemoteGame.png'
import {
  FulfillNeedsWrap,
  ContentWrap,
  TabWrap,
  TabItem,
  CardWrap,
  Left,
  Right,
} from './atoms'

const NeedsList = [
  {
    img: RemoteWork,
    name: 'Remote Work',
    title: 'Remote Access for Work',
    desc: "Effortlessly work at home with Avica's remote access solution. Access office device files, operate professional software, and handle work tasks efficiently from anywhere. Collaborate effortlessly with colleagues, share work files, and provide remote assistance with unparalleled ease. Avica empowers you to achieve maximum productivity in your remote work.",
  },
  {
    img: DistanceLearning,
    name: 'Distance Learning',
    title: 'Remote Access for Education',
    desc: 'Avica brings a groundbreaking change to remote education, giving teachers the ability to oversee student assignments and offer prompt support; to access office devices, ensuring smooth administrative tasks. By remotely accessing school devices, students can now make use of essential software like AutoCAD and Adobe Premiere Pro for their learning at home. With Avica, education obstacles are broken down, enabling learning opportunities from any location.',
  },
  {
    img: DistantITAssistance,
    name: 'IT Assistance',
    title: 'Remote Access for IT Support',
    desc: "With Avica, IT professionals can quickly and efficiently diagnose, monitor, and resolve device issues for employees or clients, regardless of their location. Whether it's troubleshooting software glitches, resolving network connectivity problems, or providing timely assistance, Avica enables IT support teams to keep businesses running smoothly without the need for physical presence. With the ability to seamlessly troubleshoot from anywhere, Avica ensures minimal downtime, increased productivity, and enhanced customer satisfaction.",
  },
  {
    img: RemoteGame,
    name: 'Remote Game',
    title: 'Remote Access for Gaming',
    desc: 'You can now play computer games on your mobile phone, complete with customizable keyboard mapping via Avica. Say goodbye to device limitations as Avica allows you to remotely access high-performance computers, enabling you to play even the most demanding games. Additionally, Avica seamlessly connects with external game controllers, ensuring an immersive and exhilarating gaming experience like never before. Level up your gaming with Avica and indulge in uninterrupted gaming adventures anytime, anywhere.',
  },
]

export default function FulfillNeeds({ isPlay }: { isPlay: boolean }) {
  const [current, setCurrent] = useState<number>(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const timer = setInterval(() => {
      if (current < NeedsList.length - 1) {
        setCurrent(current + 1)
      } else {
        setCurrent(0)
      }
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [current, isPlay])
  return (
    <FulfillNeedsWrap>
      <h2 className="title">Fulfill Diverse Remote Access Needs</h2>
      <div className="desc">
        By utilizing Avica for remote desktop access, you will be amazed at how
        easy and seamless remote connections can be!
      </div>
      <ContentWrap>
        <TabWrap>
          {NeedsList.map((item, index) => (
            <TabItem key={index} onClick={() => setCurrent(index)}>
              <div className={`box ${index === current ? 'active' : ''}`}>
                <div className="text">{item.name}</div>
              </div>
            </TabItem>
          ))}
        </TabWrap>
        <CardWrap>
          <div className="bg-one" />
          <div className="bg-two" />
          <Left>
            <h3 className="item-title">{NeedsList[current].title}</h3>
            <div className="item-desc">{NeedsList[current].desc}</div>
          </Left>
          <Right>
            <img src={NeedsList[current].img} loading="lazy" />
          </Right>
        </CardWrap>
      </ContentWrap>
    </FulfillNeedsWrap>
  )
}
