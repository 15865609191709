import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import RemoteMain from '../images/remote-main.png'
import FeatureBanner from '../components/FeatureBanner'
import RemoteMainMobile from '../images/RemoteAccessBgMobile.png'
import FulfillNeeds from '../components/FulfillNeeds'
import StartNow from '../components/StartNow'
import ListFlow from '../components/ListFlow'
import StepSlide from '../components/StepSlide'
import Question from '../components/Question'

import Support from '../images/CrossPlatformSupport.png'
import WebRemoteAccess from '../images/WebRemoteAccess.png'
import Control from '../images/LocalControl.png'
import Security from '../images/FigmaSecurityAssured.png'
import DownloadInstall from '../images/DownloadInstall.png'
import AccessControl from '../images/AccessControl.png'
import SafelyDisconnect from '../images/SafelyDisconnect.png'

const RemoteAccess = () => {
  const descList = [
    'Compatible with Windows, macOS, Android, and iOS systems.',
    'Support for initiating remote desktop access from web browsers.',
    'Clear visuals and stable remote connection for a top-notch experience.',
    'Employs end-to-end AES-256 encryption for enhanced security.',
  ]
  const faqList = [
    {
      question: 'What is remote desktop?',
      answer:
        "Remote desktop technology offers a convenient solution, allowing users to remotely access and take full control of a computer or device. By employing this technology, users can view the remote computer's screen in real-time and manipulate it as though they were physically present.",
    },
    {
      question: 'Is there free remote desktop software?',
      answer:
        'Yes, there are several free remote desktop software options available. One of the most recommended choices is Avica, a secure, seamless, and user-friendly remote desktop software that offers reliable remote desktop access and control of devices.',
    },
    {
      question: 'Is remote desktop safe to use?',
      answer:
        'Yes, remote desktop is generally safe to use if proper security measures are in place. It is important to use reputable and secure remote desktop software like Avica, which offers encryption and authentication protocols to protect the connection between devices. Additionally, it is advisable to use strong passwords and keep the software and devices updated to ensure maximum security.',
    },
    {
      question: 'What is the best remote desktop software?',
      answer:
        'The best remote desktop software depends on your specific needs and preferences. However, Avica is a highly recommended remote desktop software that offers reliable performance, easy-to-use features, and strong security measures, as it has been selected by over 10 million users from 120 countries worldwide. ',
    },
    {
      question: 'Does remote desktop need Internet?',
      answer:
        'Yes, remote desktop typically requires an internet connection to function. Avica can maintain a stable remote connection even in situations where the internet connection may not be optimal.',
    },
  ]

  const flowItemList = [
    {
      img: Support,
      img_mobile: Support,
      title: 'Cross Platform Support',
      desc: "Gone are the days of limitations and boundaries between devices and operating systems. With Avica, you can effortlessly initiate or receive remote access from Windows, macOS, iOS, or Android systems. Whether you're working on a PC, Mac, smartphone, or tablet, Avica empowers you to connect and collaborate across all the devices.",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
    {
      img: WebRemoteAccess,
      img_mobile: WebRemoteAccess,
      title: 'Remote Access via Web',
      desc: 'With just a few clicks, now you can seamlessly connect and access your remote PC directly from your browser via Avica Web App, without the hassle of any software installation on the local device!',
      tip: 'Note: Currently support outgoing connections to Windows Client.',
      ifShowDownloadBtn: true,
      ifRotation: true,
    },
    {
      img: Control,
      img_mobile: Control,
      title: 'Just Like Local Control',
      desc: 'Avica offers a remote access experience that feels just like using your computer locally. Enjoy clear visuals, low latency, and lightning-fast responsiveness as you effortlessly view files, use software, and access other resources on your remote device.',
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
    {
      img: Security,
      img_mobile: Security,
      title: 'Security Assured',
      desc: 'With end-to-end advanced AES-256 encryption, your data and communications are protected every step of the way. This industry-standard encryption algorithm guarantees that your sensitive information remains confidential, safeguarding it from unauthorized access and providing you with peace of mind during your remote sessions.',
      ifRotation: true,
      ifShowDownloadBtn: false,
    },
  ]

  const tab1List = [
    {
      img: DownloadInstall,
      title: 'Step 1. Download and Install',
      desc: 'Download and install the Avica app on local and remote devices.',
      tip: '* If you initiate a remote session using Avica Web App, just install Avica desktop client on the remote PC.',
      redirect: false,
    },
    {
      img: AccessControl,
      title: 'Step 2. Access and Control',
      desc: 'Enter the remote device\'s Avica ID on the local device and click the "Connect" button. Then, you can select Password-Free Connection or enter the Password to start a remote connection and desktop control.',
      redirect: false,
    },
    {
      img: SafelyDisconnect,
      title: 'Step 3. Safely Disconnect',
      desc: 'Once you have completed your remote tasks, ensure a secure disconnection from the remote device.',
      redirect: true,
    },
  ]

  const tabInfoList = [
    {
      tabName: '',
      ifShowSelectBox: true,
      isPlay: true,
    },
  ]

  const More = [tab1List]

  return (
    <Layout pageType="remoteAccess">
      <FeatureBanner
        title="Remote Access"
        descList={descList}
        photo={RemoteMain}
        mobile={RemoteMainMobile}
      />
      <ListFlow
        featureList={flowItemList}
        title="Connect Any Device Anytime, Anywhere"
        desc="By utilizing Avica for remote desktop access, you will be amazed at how
        easy and seamless remote connections can be!"
      />
      <StepSlide
        title="How to Achieve Remote Desktop Control?"
        ifShowTab={false}
        More={More}
        TabInfo={tabInfoList}
        className_title="title"
        guideLink="https://www.avica.link/help-center/how-to-start-a-session/"
      />
      <FulfillNeeds isPlay />
      <Question faqList={faqList} />
      <StartNow pre="Start Your" span="Remote Desktop Access" suf="Now" />
    </Layout>
  )
}

export default RemoteAccess
