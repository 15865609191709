import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

export const FulfillNeedsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 112px;
  .title {
    display: flex;
    justify-content: center;
    color: #1f2329;
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
  }
  .desc {
    display: none;
  }
  ${Media.homeLaptop`
    /* width: 1184px; */
    margin-bottom: 88px;
  `}
  ${Media.laptop`
    /* width: 1088px; */
    margin-bottom: 64px;
  `}
  ${Media.ipad`
    /* width: 896px; */
    margin-bottom: 56px;
  `}
  ${Media.phone`
    flex-direction: column;
    width: 100%;
    margin: 0.76rem 0 0.76rem;
    .title {
      text-align: center;
      font-size: 0.48rem;
      line-height: 0.6rem;
      min-width: 6.86rem;
      margin-bottom: 0.16rem;
    }
    .desc {
      width: 6.86rem;
      display: flex;
      color: #595959;
      font-size: 0.28rem;
      text-align: center;
      line-height: 0.36rem;
      margin-bottom: 0.56rem;
    }
  `}
`
export const ContentWrap = styled(BigWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const TabWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  gap: 48px;
  margin: 64px 0 94px;
  ${Media.homeLaptop`
    gap: 40px;
  `}
  ${Media.laptop`
    gap: 32px;
    margin: 48px 0 80px;
  `}
  ${Media.ipad`
    gap: 24px;
    margin: 32px 0 48px;
  `}
  ${Media.phone`
    flex-wrap: wrap;
    gap: 0.2rem;
  `}
`
export const TabItem = styled.div`
  display: flex;
  margin: 64px 0 94px;
  cursor: pointer;
  .box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 314px;
    height: 60px;
    border-radius: 6px;
    background: rgba(246, 247, 255, 1);
    &::before {
      content: '';
      position: absolute;
      inset: -1px;
      border-radius: 6px;
      background: linear-gradient(
        93.69deg,
        rgba(68, 95, 255, 0.1) 0.48%,
        rgba(85, 188, 238, 0.1) 99.66%
      );
      z-index: -1;
    }
    .text {
      color: #1f2329;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .active {
    &::before {
      content: '';
    }
    height: 60px;
    padding: 16px 32px 16px 32px;
    gap: 10px;
    border-radius: 6px;
    background: linear-gradient(290.07deg, #445fff 53.77%, #55bcee 96.28%);
    box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset;
    .text {
      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  ${Media.homeLaptop`
    width: 250px;
    .active {
      padding: 0;
    }
  `}
  ${Media.laptop`
    width: 240px;
  `}
  ${Media.ipad`
    width: 190px;
    .active {
      .text {
        font-size: 17px;
      }
    }
    .box {
      .text {
        font-size: 17px;
      }
    }
  `}
  ${Media.phone`
    width: 3.32rem;
    margin-top: 0;
    margin-bottom: 0;
    .box {
      width: 3.32rem;
      height: 0.72rem;
      border-radius: 0.08rem;
      .text {
        font-size: 0.28rem;
        line-height: 0.36rem;
      }
    }
    .active {
      width: 3.32rem;
      height: 0.76rem;
      gap: 0.2rem;
      border-radius: 0.08rem;
      box-shadow: 0.04rem 0.08rem 0.2rem 0 rgba(255, 255, 255, 0.2) inset;
      .text {
        color: #ffffff;
        font-size: 0.28rem;
        line-height: 0.36rem;
      }
    }
  `}
`
export const CardWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(69, 96, 255, 0.04);
  height: 420px;
  padding-left: 80px;
  border-radius: 24px;
  position: relative;
  .bg-one {
    position: absolute;
    left: 50px;
    width: 1085.553px;
    height: 280px;
    flex-shrink: 0;
    border-radius: 1000.553px;
    background: #7c8fff;
    filter: blur(60px);
    z-index: -1;
  }
  .bg-two {
    position: absolute;
    right: 50px;
    width: 693px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 693px;
    background: #7c8fff;
    filter: blur(60px);
    z-index: -1;
  }
  ${Media.homeLaptop`
    .bg-one {
      height: 261.09px;
    }
    .bg-two {
      height: 161px;
    }
  `}
  ${Media.laptop`
    padding-left: 52px;
    gap: 30px;
  `}
  ${Media.ipad`
    flex-direction: column-reverse;
    justify-content: start;
    width: 894px;
    height: 555px;
    padding: 16px 16px 32px 16px;
    border-radius: 24px;
    gap: 24px;
    .bg-one {
      width: 622px;
      height: 461.09px;
      border-radius: 1200.553px;
      left: 60px;
      top: 50px;
    }
    .bg-two {
      width: 502px;
      height: 431.09px;
      border-radius: 1224px;
      top: 50px;
    }
  `}
  ${Media.phone`
    height: auto;
    width: 6.86rem;
    flex-direction: column-reverse;
    box-shadow: 0 0 0.8rem 0 #4560ff0a;
    margin-top: 0.48rem;
    padding: 0.2rem;
    .bg-one {
      width: 4.6rem;
      height: 8.8rem;
      border-radius: 2rem;
      left: 0.9rem;
    }
    .bg-two {
      width: 0.9rem;
      height: 2rem;
      border-radius: 2rem;
    }
  `}
`
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  .item-title {
    color: #1f2329;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
  .item-desc {
    color: #595959;
    font-size: 18px;
    line-height: 27px;
    margin-top: 12px;
  }
  ${Media.homeLaptop`
    margin-right: 24px;
    .item-title {
      font-size: 30px;
    }
  `}
  ${Media.ipad`
    width: 862px;
    margin-right: 0;
    padding: 0 16px;
    .item-title {
      font-size: 28px;
      line-height: normal;
    }
  `}
  ${Media.phone`
    width: 100%;
    padding: 0.24rem 0.1rem;
    margin-right: 0;
    .item-title {
      font-weight: 700;
      font-size: 0.44rem;
      line-height: 0.56rem;
    }
    .item-desc {
      font-size: 0.28rem;
      line-height: 0.42rem;
      margin-top: 0.12rem;
    }
  `}
`
export const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0;
  ${Media.homeLaptop`
    img {
      width: 570px;
    }
  `}
  ${Media.laptop`
    img {
      width: 500px;
    }
  `}
  ${Media.ipad`
    padding: 0;
    img {
      width: 862px;
      height: 261.09px;
      object-fit: cover;
      border-radius: 24px;
      object-position: top;
    }
  `}
  ${Media.phone`
    padding: 0;
    img {
      width: 6.46rem;
    }
  `}
`
